html,
body {
    color: #515861;
    margin: 0;
    padding: 0;
    height: 100%;
    font-family: 'DM Sans', sans-serif;
}

.wrapper {
    display: flex;
    display: flex;
    min-height: 100vh;
}

.image {
    flex: 1;
    /* Take available space */
    background-size: cover;
    background-image: url('../../assets/img/bg.webp');
}

.form {
    flex-basis: 450px;
    border-right: rem(1px) solid light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-7));
    min-height: rem(900px);
    max-width: rem(450px);
    padding-top: rem(80px);

    /* @media (max-width: $mantine-breakpoint-sm) {
        max-width: 100%;
    } */
}

.title {
    /* color: light-dark(var(--mantine-color-black), var(--mantine-color-white)); */
    font-size: 22px;
    font-family: 'DM Sans', sans-serif;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    /* border: inherit; */
    -webkit-text-fill-color: inherit;
    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
    transition: background-color 5000s ease-in-out 0s;
}